<template>
  <div class="teamView">
    <van-sticky>
      <van-nav-bar title="团队列表" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <span class="myTjNum" v-if="!id">团队:{{ $store.getters.userinfo.teamCount }}人 团队业绩:{{ $store.getters.userinfo.teamYeji }}</span>
    <van-list v-model="loading" :finished="finished" loading-text="..." finished-text="没有更多了" :immediate-check="immediate" @load="onLoad">
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item" @click="onDetail(item.id, key)">
        <div class="left">
          <span class="desc">会员账号: {{ item.mobile }}</span>
          <span class="desc">团队人数: {{ item.teamCount }}</span>
          <span class="desc">团队业绩: {{ item.teamYeji }}</span>
        </div>
        <div class="right" v-if="item.parentCount > 0">
          <van-icon size="0.7rem" name="arrow" />
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { getTeam } from '@/api/users';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
      id: '',
      fromPath: '',
      immediate: false,
    };
  },
  created() {
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      if (from.path.indexOf('/main/user/team') != -1) {
        this.fromPath = from.path;
      }
      this.onLoad();
      this.immediate = false;
      this.id = to.params.id;
      this.loading = false;
      this.page = 1;
      this.list = [];
      this.finished = false;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onDetail(id, key) {
      if (this.list[key].parentCount == 0) {
        return;
      }
      this.$router.push({
        path: '/main/user/team/' + id,
      });
    },
    //获取邀请列表
    GetTeam() {
      getTeam({
        id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.GetTeam();
    },
  },
};
</script>
<style lang="stylus">
.teamView
  .myTjNum
    margin: 0.5rem
    display: block
    text-align: center
    font-weight: bold
    font-size: 0.42rem
    color: #771313
  .van-list
    margin-top: 0.3rem
    padding-bottom: 1.3rem
    .xiax_item
      display: flex
      padding: 0.15rem 0.4rem
      .van-cell__value
        display: flex
        .left
          flex: 1
          color: #777
          font-size: 0.36rem
          padding: 6px
          span
            display: block
        .right
          color: $grayTextColor
          display: flex
          align-items: center
          justify-content: center
</style>
